import React, { Component } from 'react';
class NotFound extends Component {
    render() {
        return (
            <article id="contact" className="content-article active-article add-padding animated fadeIn">
                <section className="hero is-primary">
                    <div className="hero-body">
                        <h1 className="title flex-center-content">
                            404 - Not Found
                            </h1>
                    </div>
                </section>
                <br></br>
                <p>This is not the page you are looking for.</p>
            </article>
        );
    }
}

export default NotFound;