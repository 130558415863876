import React, { Component } from "react";
class PrivacyPolicy extends Component {
  render() {
    return (
      <article
        id="privacy-policy"
        className="content-article active-article add-padding animated fadeIn"
      >
        <div className="hero is-primary">
          <div className="hero-body">
            <h1 className="title flex-center-content">Privacy Policy</h1>
          </div>
        </div>
        <div className="client-info add-top-10">
          <p>
            I use Google Analytics to track traffic on this site. This data is
            handled in accordance with the following information: <a href="https://www.google.com/policies/privacy/partners/">"How Google
            uses data when you use our partners' sites or apps"</a> By using the
            site, you consent to this use of cookies and other tracking methods
            for these purposes.
          </p>
        </div>
      </article>
    );
  }
}

export default PrivacyPolicy;
