import React, { Component } from 'react';
class Skills extends Component {
    render() {
        return (
          <article
            id="skills"
            className="content-article active-article add-padding animated fadeIn"
          >
            <section className="hero is-primary">
              <div className="hero-body">
                <h1 className="title flex-center-content">Skills</h1>
              </div>
            </section>
            <br></br>
            <b>DevOps Skills:</b>
            <ul className="values-list">
              <li>GitHub Actions</li>
              <li>Docker &amp; Docker Compose</li>
              <li>
                CI/CD pipeline creation with unit tests, E2E, and code coverage
              </li>
            </ul>
            <b>Front-End skills:</b>
            <p>
                <em>(Note: This is not a random list like most job postings. I'm an expert in every one of these.)</em>
            </p>
            <ul className="values-list">
              <li>React</li>
              <li>Angular</li>
              <li>StencilJS (Web Components)</li>
              <li>AngularJS (1.x)</li>
              <li>TypeScript</li>
              <li>
                Unit Testing (Jest is my favorite, but I've also used Karma,
                Jasmine, Mocha, &amp; Chai)
              </li>
              <li>
                Integration and E2E Testing with{" "}
                <a href="https://cypress.io">Cypress</a>
              </li>
              <li>RxJS</li>
              <li>
                NgRx -- see my{" "}
                <a href="https://github.com/WilliamJohnsonJr/pantry-buddy">
                  PantryBuddy
                </a>{" "}
                repo for an example of NgRx best practices, but be warned: <a href="https://www.youtube.com/watch?v=omnwu_etHTY" target="_blank" rel="noreferrer">you might not need NgRx</a>.
              </li>
              <li>ES6/ES2015 JavaScript code and modules</li>
              <li>
                <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">
                  WCAG 2.x
                </a>{" "}
                compliant UI code and Semantic HTML5
              </li>
              <li>CSS/SASS/SCSS</li>
              <li>Responsive Design</li>
              <li>Build Tools (webpack, Gulp, etc.)</li>
            </ul>
            <br></br>
            <b>Mobile development (Android/iOS):</b>
            <ul className="values-list">
              <li>NativeScript</li>
              <li>Ionic 1.x</li>
              <li>Ionic 3+</li>
            </ul>
            <br></br>
            <b>Back-End skills:</b>
            <br></br>
            While most of my development experience is on the Front-End, I have
            built out Back-End components for some of my apps using the
            following languages and frameworks:
            <ul className="values-list">
              <li>Ruby with Ruby on Rails</li>
              <li>
                Node.js with <a href="https://expressjs.com">ExpressJS</a> and{" "}
                <a href="https://github.com/sequelize/sequelize">Sequelize</a>/
                <a href="https://github.com/sequelize/cli">Sequelize-CLI</a>,
                and/or <a href="https://adonisjs.com/">AdonisJS</a>
              </li>
            </ul>
          </article>
        );
    }
}

export default Skills;