import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Routes
} from 'react-router-dom'
import logo from '../assets/logo.png';
import AboutMe from './AboutMe';
import Projects from './Projects';
import Skills from './Skills';
import Principles from './Principles';
import ContactMe from './ContactMe';
import PrivacyPolicy from './PrivacyPolicy';
import NotFound from './NotFound';

const Navigation = () => (
  <Router>
    <div id="main-container-div">
      <div id="main-container-div-background" aria-hidden="true"></div>
      <header>
        <nav className="navbar is-dark add-padding">
          <div className="navbar-brand">
            <Link to="/" aria-label="Home" tabIndex="0">
              <figure className="image is-64x64 pointer">
                <img
                  src={logo}
                  alt="WilliamJohnsonJr logo."
                  id="logo-image"
                ></img>
              </figure>
            </Link>
            <button
              type="button"
              id="navbar-burger"
              className="navbar-burger burger"
              data-target="nav-menu"
              aria-label="Toggle navigation menu"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div id="nav-menu" className="navbar-menu">
            <ul className="navbar-start center-navbar-start" role="menu">
              <li>
              <Link
                to="/"
                role="menuitem"
                id="about-me-link"
                className="navbar-item"
                data-target="nav-menu"
              >About Me</Link>
              </li>
              <li>
              <Link
                to="/projects"
                role="menuitem"
                id="projects-link"
                className="navbar-item"
                data-target="nav-menu"
              >Projects</Link>
              </li>
              <li>
              <Link
                to="/skills"
                role="menuitem"
                id="skills-link"
                className="navbar-item"
                data-target="nav-menu"
              >Skills</Link>
              </li>
              <li>
              <Link
                to="/principles"
                role="menuitem"
                id="principles-link"
                className="navbar-item"
                data-target="nav-menu"
              >My Principles</Link>
              </li>
              <li>
              <Link
                to="/contact"
                role="menuitem"
                id="contact-link"
                className="navbar-item"
                data-target="nav-menu"
              >Contact</Link>
              </li>
              <li>
              <Link
                to="/privacy-policy"
                role="menuitem"
                id="privacy-policy-link"
                className="navbar-item"
                data-target="nav-menu"
              >Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <Link
            to="/"
            id="full-name-nav-link"
            className="white-text add-padding pointer flex align-center"
          >
            <h3 className="title-text" id="name-nav-link">
              William Johnson Jr
            </h3>
          </Link>
        </nav>
      </header>
      <main className="container">
        <Routes>
          <Route exact path="/" element={<AboutMe />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/principles" element={<Principles />} />
          <Route path="/contact" element={<ContactMe />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <footer className="hero is-dark my-footer">
        <div className="hero-body flex-center-content">
          <h3 id="site-map-label">Site Map</h3>
          <ul id="site-map" aria-labelledby="site-map-label">
            <li>
              <Link to="/">About Me</Link>
            </li>
            <li>
              <Link to="/projects">Projects</Link>
            </li>
            <li>
              <Link to="/skills">Skills</Link>
            </li>
            <li>
              <Link to="/principles">My Principles</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
          <p id="footer-text">
            Copyright &#x00A9; 2019-{new Date().getFullYear()} William Johnson, Jr. All Rights Reserved.
          </p>
        </div>
      </footer>
    </div>
  </Router>
);
export default Navigation