import React, { Component } from 'react';
import logo from '../assets/logo.png';
import gitHubLogo from '../assets/GitHub-Mark-64px.png';
import linkedInLogo from '../assets/In-2C-66px-R.png';

class AboutMe extends Component {
    render() {
        return (
          <article
            id="about-me"
            className="content-article active-article add-padding animated fadeIn"
          >
            <div className="hero is-primary">
              <div className="hero-body">
                <h1 className="title flex-center-content">About Me</h1>
              </div>
            </div>
            <br></br>
            <div className="flex-center-content">
              <img
                src={logo}
                alt="WilliamJohnsonJr logo."
                id="profile-photo"
              ></img>
            </div>
            <br></br>
            <h2 id="tagline" className="flex-center-content center-text">
              Thrill seeker. Coffee drinker. Web Developer.
            </h2>
            <div className="flex-center-content add-padding">
              <p>
                Senior Software Engineer at{" "}
                <a href="https://truework.com" target="_blank" rel="noreferrer">
                  Truework
                </a>
              </p>
            </div>
            <br></br>
            <div className="flex space-around align-center">
              <a
                href="https://github.com/WilliamJohnsonJr"
                aria-label="Click to go to Will's GitHub."
                className="image-anchor"
                target="_blank"
                rel="noopener noreferrer"
              >
                <figure className="image is-64x64 pointer">
                  <img
                    src={gitHubLogo}
                    alt="GitHub logo for Will's GitHub"
                  ></img>
                </figure>
              </a>
              <a
                href="https://www.linkedin.com/in/williamtjohnsonjr"
                aria-label="Click to go to Will's LinkedIn profile."
                id="linked-in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex align-center">
                  <img src={linkedInLogo} alt="LinkedIn Logo"></img>
                </div>
              </a>
              <a
                href="mailto:will@williamjohnsonjr.com"
                aria-label="Click to send an email to Will."
                className="image-anchor"
              >
                <div className="flex-center-content">
                  <i
                    className="fa fa-envelope fa-4x"
                    aria-hidden="true"
                    alt="Email logo"
                  ></i>
                </div>
              </a>
            </div>
            <br></br>
            <p className="center-text p-6">
              Front-End Engineer (and former Principal UI team lead at{" "}
              <a href="https://adp.com" target="_blank" rel="noreferrer">
                ADP
              </a>
              ) with extensive experience with Angular, React, Typescript, Jest,
              Cypress E2E testing, Continuous Integration and Continuous
              Deployment, Domain-Driven Design, Accessibility (a11y), and Web
              Components in enterprise, consulting, and startup contexts. I love
              building clean, performant, and accessible apps. I enjoy mentoring
              passionate developers and teams who want to grow.
            </p>
          </article>
        );
    }
}

export default AboutMe;