import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.css';
import './App.css';
import Navigation from './Views/Navigation';

class App extends Component {
  render() {
    return (
      <Navigation/>
    );
  }

  componentDidMount() {
    document.addEventListener('DOMContentLoaded', function () {
      const pageName = window.location.href.split('/')[3];
      switch(pageName) {
        case '':
          document.getElementById('about-me-link').classList.add('is-active');
          break;
        case 'projects':
          document.getElementById('projects-link').classList.add('is-active');
          break;
        case 'skills':
          document.getElementById('skills-link').classList.add('is-active');
          break;
        case 'principles':
          document.getElementById('principles-link').classList.add('is-active');
          break;
        case 'contact':
          document.getElementById('contact-link').classList.add('is-active');
          break;
        default :
          break;
      }

      let resetActiveLink = () => {
        let $target = document.getElementById('nav-menu');
        // Remove is-active class from all nav items
        $navbarItems.forEach(function($myEl){
          $myEl.classList.remove('is-active');
        })

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        document.getElementById('about-me-link').classList.toggle('is-active');
        $target.classList.remove('is-active');
        document.getElementById('navbar-burger').classList.remove('is-active');
      }

      // Get all "navbar-burger" elements
      let $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
      let $navbarItems = Array.prototype.slice.call(document.querySelectorAll('.navbar-item'), 0);
      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
    
        // Add a click event on each of them
        $navbarBurgers.forEach(function ($el) {
          $el.addEventListener('click', function () {
    
            // Get the target from the "data-target" attribute
            let target = $el.dataset.target;
            let $target = document.getElementById(target);
    
            // Toggle the class on both the "navbar-burger" and the "navbar-menu"
            $el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
          });
        });
      }

      if ($navbarItems.length > 0) {
        $navbarItems.forEach(function ($el) {
          $el.addEventListener('click', function () {
            // Get the target from the "data-target" attribute
            let target = $el.dataset.target;
            let $target = document.getElementById(target);
            
            // Remove is-active class from all nav items
            $navbarItems.forEach(function($myEl){
              $myEl.classList.remove('is-active');
            })

            // Toggle the class on both the "navbar-burger" and the "navbar-menu"
            $el.classList.toggle('is-active');
            $target.classList.toggle('is-active');
            document.getElementById('navbar-burger').classList.toggle('is-active');
          });
        });
      }
      document.getElementById('logo-image').addEventListener('click', function () {
       resetActiveLink();
      });

      document.getElementById('name-nav-link').addEventListener('click', function() {
        resetActiveLink();
      })

    });

    let myViewHeight = document.getElementsByTagName('html')[0].clientHeight;
    for (let x=0; x<(myViewHeight / 10); x++) {
      let span = document.createElement("span");
      span.setAttribute('aria-hidden', 'true');
      span.innerText = '01011101010110101101011010111010101';
      span.className = 'main-container-background';
      document.getElementById('main-container-div-background').appendChild(span);
    }
  }
}

export default App;
