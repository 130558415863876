import React, { Component } from 'react';
class Principles extends Component {
    render() {
        return (
          <article
            id="principles"
            className="content-article active-article add-padding animated fadeIn"
          >
            <section className="hero is-primary">
              <div className="hero-body">
                <h1 className="title flex-center-content">My Principles</h1>
              </div>
            </section>
            <div className="add-top-10">
              <p className="client-info">
                I enjoy building quality websites that empower people to do the
                things they love. My software is built with five core principles
                (MEAPS) in mind:
              </p>
              <br></br>
              <ul className="values-list">
                <li className="client-info">
                  <p>
                    Maintainable (cleanly written and tested, SOLID design and
                    basic tests are a minimum)
                  </p>
                </li>
                <li className="client-info">
                  <p>Ethical and Good for the World</p>
                </li>
                <li className="client-info">
                  <p>Accessible</p>
                </li>
                <li className="client-info">
                  <p>Performant</p>
                </li>
                <li className="client-info">
                  <p>Secure</p>
                </li>
              </ul>
            </div>
          </article>
        );
    }
}

export default Principles;